import { graphql, PageProps } from 'gatsby'
import React, { useCallback, useMemo } from 'react'
import RepairContent from '../components/RepairContent'
import { SavequoteModelQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'
import { Helmet } from 'react-helmet'
import cgus from '../data/cgus'
import useSaveBrandsQuery from '../hooks/useBrandsQuery'
import { RepairOtherBrandPageContext } from './RepairOtherBrandPage.context'
import { repairModelPageBuilder } from './RepairModelPage.context'
import capitalizeFirstLetterOfWords from '../utils/capitaliseFirstLetter'
import { getProductByUrlCode } from '../data/productTypes'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

const usePathBuilder = (productType: string, brandName: string) => {
  return useCallback(
    (item: IRepairModel) => {
      return repairModelPageBuilder(brandName, item.slugFull ?? '', productType)
    },
    [brandName]
  )
}

const useModels = (data: SavequoteModelQuery): IRepairModel[] => {
  return useMemo(() => {
    return data.allSaveMobileModelList.nodes.filter(notEmpty) ?? []
  }, [data])
}

const RepairBrandPage: React.FC<
  PageProps<SavequoteModelQuery, RepairOtherBrandPageContext>
> = ({ data, pageContext }) => {
  const models = useModels(data)
  const brands = useSaveBrandsQuery()

  const pathBuilder = usePathBuilder(
    pageContext.productTypeUrlCode,
    pageContext.brandNameLowerCase
  )

  const filteredBrands = brands.filter(
    (_brand) =>
      pageContext.brandNameLowerCase === (_brand.name ?? '').toLocaleLowerCase()
  )

  const ogImageUrl =
    filteredBrands.length > 0
      ? filteredBrands[0]?.imgUrl ??
        `${process.env.GATSBY_SITE_URL}images/logo-Save-blanc-v-blanc.png`
      : `${process.env.GATSBY_SITE_URL}images/logo-Save-blanc-v-blanc.png`

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`Réparez votre produit de marque ${capitalizeFirstLetter(
              pageContext.brandNameLowerCase
          )} avec le leader européen de la réparation de proximité dans un de nos ${pageContext.shopsCount} points de vente Save - Réparation garantie 1 an.`}
        />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>

      <RepairContent>
        <RepairContent.Card
          index={2}
          title={
            <RepairContent.SeoHeader
              title={`Réparation de ${getProductByUrlCode(
                pageContext.productTypeUrlCode
              )?.arianeLabel.toLocaleLowerCase()} ${
                pageContext.brandNameLowerCase
                  ? capitalizeFirstLetterOfWords(pageContext.brandNameLowerCase)
                  : ''
              }`}
              subtitle={`Choisir le modèle de ${getProductByUrlCode(
                pageContext.productTypeUrlCode
              )?.arianeLabel.toLocaleLowerCase()}`}
            />
          }
        >
          <RepairContent.Grid
            items={models}
            pathBuilder={pathBuilder}
            withSearch="Rechercher un modèle"
          />
        </RepairContent.Card>

        {cgus[pageContext.brandNameLowerCase] && (
          <RepairContent.Cgu
            dangerouslySetInnerHTML={{
              __html: cgus[pageContext.brandNameLowerCase],
            }}
          />
        )}
      </RepairContent>
    </>
  )
}

type IRepairModel = NonNullable<
  Unpacked<NonNullable<SavequoteModelQuery['allSaveMobileModelList']['nodes']>>
>

export const query = graphql`
  query SavequoteModel($brandName: String!) {
    allSaveMobileModelList(
      filter: { id: { regex: $brandName } }
      sort: { fields: weight, order: DESC }
    ) {
      nodes {
        imageFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100, width: 170)
          }
        }
        name
        slugFull
      }
    }
  }
`

export default RepairBrandPage
